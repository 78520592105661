<script setup>
import { WdsItemPicker } from '@wds/components';
import { CURRENCIES, DEFAULT_CURRENCY } from '~/config/currencies';
import { useCurrencyStore } from '~/stores/currency';

const { t } = useI18n();
const { isBelowMediumScreen } = useMq();

const currencyStore = useCurrencyStore();

const getCurrencyList = computed(() => {
  return CURRENCIES.map((currency) => ({
    label: `${t(currency.transcode)} (${currency.code})`,
    value: currency.code,
  }));
});

async function handleCurrencySelected(currency) {
  const selectedCurrency = currency || DEFAULT_CURRENCY.code;
  await currencyStore.setCurrency(selectedCurrency);

  const targetUrl = new URL(document.location.href);
  targetUrl.searchParams.delete('currency');

  navigateTo(targetUrl.toString(), { external: true });
}

function closeCurrencyPicker() {
  if (currencyStore.isCurrencyPickerOpen) {
    currencyStore.toggleCurrencyPicker(false);
  }
}
</script>

<template>
  <div class="currency-picker">
    <WdsItemPicker
      :is-open="currencyStore.isCurrencyPickerOpen"
      :heading="$t('t_CURRENCY')"
      :items="getCurrencyList"
      :selected="currencyStore.getCurrentCurrency?.code"
      :is-modal="!isBelowMediumScreen"
      :search-bar="true"
      @close-item-picker="closeCurrencyPicker"
      @item-selected="handleCurrencySelected"
    />
  </div>
</template>
